import { useReducer, createContext, useContext } from "react";

const initialState = {
  toasts: [],
};

const reducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case 'ADD_TOAST':
      return {
        ...state,
        toasts: [...state.toasts, action.value]
      };
    default:
      return state;
  }
}

export const useToasts = () => useContext(ToastsContext);

const ToastsContext = createContext({
  state: initialState,
  dispatch: () => { }
});

export const ToastsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    state,
    dispatch
  };

  return <ToastsContext.Provider value={value}>
    {children}
  </ToastsContext.Provider>;
};

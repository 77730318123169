import { Route, Routes, Outlet } from 'react-router-dom';
import { Toasts } from './components/Toasts';
import { Screen } from './components/Screen';
import { AccountSetup } from './sites/pay/views/AccountSetup';
import { InspectionForm } from './sites/inspection/views/InspectionForm';
import { AuthLinkRequest } from './sites/auth/views/AuthLinkRequest';

const EntryScreen = () => <Screen>
  <Outlet />
</Screen>;

const App = () => {
  switch (process.env.REACT_APP_SITE) {
    case 'auth':
      return <>
        <Toasts />
        <Routes>
          <Route path='/' element={<EntryScreen />}>
            <Route index element={<AuthLinkRequest />} />
          </Route>
        </Routes>
      </>;
    case 'pay':
      return <>
        <Toasts />
        <Routes>
          <Route path='/' element={<EntryScreen />}>
            <Route index element={<AccountSetup />} />
          </Route>
        </Routes>
      </>;
    case 'inspection':
      return <>
        <Toasts />
        <Routes>
          <Route path='/' element={<EntryScreen />}>
            <Route index element={<InspectionForm />} />
          </Route>
        </Routes>
      </>;
    default:
      return <>
        <Toasts />
        <Routes>
          <Route path='/' element={<EntryScreen />}>
            <Route index element={<div>
              <h1>You must select the site via `REACT_APP_SITE`. For example, `REACT_APP_SITE='pay' npm start` when developing locally.</h1>
            </div>} />
          </Route>
        </Routes>
      </>;
  }
};

export default App;
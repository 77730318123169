import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastsProvider } from './contexts/useToasts';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Router>
    <React.StrictMode>
      <ToastsProvider>
        <App />
      </ToastsProvider>
    </React.StrictMode>
  </Router>
);
